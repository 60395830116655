import { TokenResultDto } from "@/api-client";
import { Authentication } from "@/network/api";
import { Store as VuexStore, Module, GetterTree, MutationTree } from "vuex";
import store, { RootState} from "..";

// interface TokenResponse {}

export enum MutationTypes {
  SetIsLoading = "SET_Is_Loading",
  SetIsLoadingOverride = "SET_Is_Loading_Override",
}

export type MiscState = {
  isLoading: boolean;
  isLoadingOverride: boolean;
};

export const state: MiscState = {
  isLoading: false,
  isLoadingOverride: false,
};

type Getters = {
  isLoading(state: MiscState): boolean;
  isLoadingOverride(state: MiscState): boolean;
};

export const getters: GetterTree<MiscState, RootState> & Getters = {
  isLoading(state) {
    return state.isLoading; // state.isLoggedIn;
  },
  isLoadingOverride(state) {
    return state.isLoadingOverride; // state.isLoggedIn;
  },
};

type Mutations = {
  [MutationTypes.SetIsLoading](state: MiscState, isLoading: boolean): void;
  [MutationTypes.SetIsLoadingOverride](state: MiscState, isLoadingOverride: boolean): void;
};

export const mutations: MutationTree<MiscState> & Mutations = {
  [MutationTypes.SetIsLoading](state: MiscState, value: boolean) {
    state.isLoading = value;
  },
  [MutationTypes.SetIsLoadingOverride](state: MiscState, value: boolean) {
    state.isLoadingOverride = value;
  },
};

export const MiscModule: Module<MiscState, RootState> = {
  state,
  getters,
  mutations,
  actions: {
    setIsLoading: async (context, params) => {
      context.commit(MutationTypes.SetIsLoading, params);
    },
    setIsLoadingOverride: async (context, params) => {
      context.commit(MutationTypes.SetIsLoadingOverride, params);
    },
  },

  namespaced: true,
};
