
import path from "path";
import { Component, Prop, Vue } from "vue-property-decorator";
import { RouteConfig } from "vue-router";
import { isExternal } from "@/utils/validate";
import SidebarItemLink from "./SidebarItemLink.vue";
import { hasClaim } from "@/utils/claims";
import store from "@/store";

@Component({
  // Set 'name' here to prevent uglifyjs from causing recursive component not work
  // See https://medium.com/haiiro-io/element-component-name-with-vue-class-component-f3b435656561 for detail
  name: "SidebarItem",
  components: {
    SidebarItemLink,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private item!: RouteConfig;
  @Prop({ default: false }) private isCollapse!: boolean;
  @Prop({ default: true }) private isFirstLevel!: boolean;
  @Prop({ default: "" }) private basePath!: string;

  navItems: any[] = []

  get theOnlyOneChild() {
    if (this.showingChildNumber > 1) {
      return null;
    }
    if (this.item.children) {
      for (const child of this.item.children) {
        if (!child.meta || !child.meta.hidden) {
          return child;
        }
      }
    }
    // If there is no children, return itself with path removed,
    // because this.basePath already conatins item's path information
    return { ...this.item, path: "" };
  }

  get showingChildNumber() {
    if (this.item.children) {
      const showingChildren = this.item.children.filter((item) => {
        if (item.meta && item.meta.hidden) {
          return false;
        } else {
          return true;
        }
      });
      return showingChildren.length;
    }
    return 0;
  }

  get roles() {
    return store.getters["user/role"] as any;
  }

  get CurrentMenuType() {
    return (this.$router as any).currentRoute.meta.menuType;
  }

  async created() {
    await this.getNavItems(this.item)
  }

  async canView(child: any) {
    if(child) {
      const hasMeta = Object.prototype.hasOwnProperty.call(child, "meta")
      const hasClaimMeta = hasMeta ? Object.prototype.hasOwnProperty.call(child.meta, "claim") : false

      if (child && (hasMeta && (child.meta.hidden != true)) && hasClaimMeta) {
        let hasClaimAccess = false;

        if(Array.isArray(child.meta.claim) ) {
          for(let i = 0; i < child.meta.claim.length ;i++) {
            hasClaimAccess = await hasClaim(child.meta.claim[i].claimType);

            if(hasClaimAccess) {
              break;
            }
          }
        } else {
          hasClaimAccess = await hasClaim(child.meta.claim.claimType);
        }
        
        return hasClaimAccess ? child : false;
      } else if (child && !hasMeta || (hasMeta && child.meta.hidden != true)) {
        return child;
      }
    }

    return false;
  }

  async getNavItems(items:any) {
    let itemsToShow: any[] = []
    if(items.children?.length) {
      itemsToShow = await Promise.all(items.children.map(async (child:any) => await this.canView(child)))
    }
    itemsToShow = itemsToShow.filter((item) => {
      return item !== false;
    });
    this.navItems = itemsToShow;
  }

  private resolvePath(routePath: string) {
    if (isExternal(routePath)) {
      return routePath;
    }
    if (isExternal(this.basePath)) {
      return this.basePath;
    }
    return path.resolve(this.basePath, routePath);
  }
}
