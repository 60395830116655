import { render, staticRenderFns } from "./SidebarLogo.vue?vue&type=template&id=e9ea6bce&scoped=true&"
import script from "./SidebarLogo.vue?vue&type=script&lang=ts&"
export * from "./SidebarLogo.vue?vue&type=script&lang=ts&"
import style0 from "./SidebarLogo.vue?vue&type=style&index=0&id=e9ea6bce&prod&lang=scss&scoped=true&"
import style1 from "./SidebarLogo.vue?vue&type=style&index=1&id=e9ea6bce&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9ea6bce",
  null
  
)

export default component.exports