import { ApplicationClaimType } from "@/api-client";
import store from "@/store";

export async function hasClaim(claimType: ApplicationClaimType) {
  return new Promise<boolean>((resolve, reject) => {
    const claims = store.getters["user/claims"] as Map<ApplicationClaimType, string | string[]> | null;
    let result = false;

    if (claims?.size) {
      if (claims.has(claimType)) {
        let value = claims.get(claimType);
        let requiredClaimValue = null;

        requiredClaimValue = "super"
        
        if(requiredClaimValue) {
          result = value === requiredClaimValue || (value instanceof Array && value.includes(requiredClaimValue));
        }
      }
    }

    resolve(result)
  });
}

export async function handleClaims(data: object) {
  return new Promise<Map<ApplicationClaimType, string | string[]> | null>((resolve, reject) => {
    let claims: Map<ApplicationClaimType, string | string[]> = new Map();

    Object.entries(data).forEach((entry: any) => {
      if ((ApplicationClaimType as any)[`${entry[0]}`]) {
        const value = entry[1] === "" ? "super" : entry[1];
        claims.set(`${entry[0] as ApplicationClaimType}`, value);
      }
    });

    // Map did not work in local storage, had to convert to Array
    claims.size > 0 ? resolve(claims) : resolve(null);
  });
}
