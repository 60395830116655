import { TokenResultDto } from "@/api-client";
import { Authentication } from "@/network/api";
import { Store as VuexStore, Module, GetterTree, MutationTree } from "vuex";
import store, { RootState} from "..";
import router from "@/router";
import { handleError } from "@/utils/auth";
import { Message } from "element-ui";

// interface TokenResponse {}

export enum SocialProviders {
  Google,
  Facebook,
  Apple,
}

export enum MutationTypes {
  SetToken = "SET_Auth_Token",
}

export type AuthState = {
  token: TokenResultDto | null;
  expiry: string;
  isLoggedIn: boolean;
};

export const state: AuthState = {
  token: null, // use getter stright out of localstore or cookies
  expiry: "",
  isLoggedIn: getTokenState() != null,
};

type Getters = {
  token(state: AuthState, rootState: RootState): TokenResultDto | null;
  expiry(state: AuthState): string;
  isLoggedIn(state: AuthState): boolean;
};

function getTokenState(_state?: AuthState): TokenResultDto | null {
  if (_state && _state!.token != null) {
    return _state!.token;
  }
  const vuex = localStorage.getItem("vuex");
  const store = JSON.parse(vuex!);
  if (store == null) {
    return null;
  }
  if (store["auth"] == null) {
    return null;
  }
  const tkn = store["auth"]["token"];
  if (tkn != null) {
    if (_state) {
      _state!.token = tkn;
    }
    return tkn;
  }
  return null;
}

export const getters: GetterTree<AuthState, RootState> & Getters = {
  token(state) {
    return getTokenState(state);
  },
  expiry() {
    return store.getters["user/expiry"];
  },
  isLoggedIn(state) {
    // if (state.isLoggedIn )
    // var test = getTokenState(state) == null ? false : true;
    // console.log(`User Logged in : ${test}`)
    return state.isLoggedIn; // state.isLoggedIn;
  },
};

type Mutations = {
  [MutationTypes.SetToken](state: AuthState, token: any): void;
};

export const mutations: MutationTree<AuthState> & Mutations = {
  [MutationTypes.SetToken](state: AuthState, token: any) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["auth"]) {
      store["auth"] = {};
    }
    store["auth"]["token"] = token;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.token = { ...token };
    if (token) {
      state.isLoggedIn = true;
    } else {
      state.expiry = ""
      state.isLoggedIn = false;
    }
  },
};

let _refreshingToken: Promise<void> | null = null;

export const AuthModule: Module<AuthState, RootState> = {
  state,
  getters,
  mutations,
  actions: {
    login: async (context, params) => {
      return new Promise<void>((resolve, reject) => {
        Authentication.apiAccountLoginPost({
          email: params.email!,
          password: params.password!,
        })
          .then(async (res) => {
            let token = res.data
            context.commit(MutationTypes.SetToken, token);
            await store.dispatch("user/getUserInfo", { token: token.authenticationToken });
            
            resolve();
          })
          .catch((error) => {
            console.error("LOGIN FAILED", error);
            if (error && Object.prototype.hasOwnProperty.call(error, "response")) {
              if(error.response?.status === 403) {
                Message.error({ message: "Incorrect email or password.", showClose: true });
              } else {
                Message.error({ message: "An unexpected error has occurred.", showClose: true });
              }
            }
            reject();
          });
      });
    },
    logout: async (context, params) => {
      return new Promise<void>((resolve, reject) => {
        context.commit(MutationTypes.SetToken, null);
        store.dispatch("user/clearProfile").then(() => {
          
          let path = window.location.pathname
          router.push({name: "Login"});
          resolve()
        });
      });
    },
    refresh: (context, params) => {
      return (_refreshingToken ??= new Promise<void>((resolve, reject) => {
        const authenticationToken = (state.token! as any)["authenticationToken"];
        const refreshToken = (state.token! as any)["refreshToken"];
        Authentication.apiAccountRefreshTokenPost(authenticationToken, refreshToken)
          .then(async (res) => {
            if(res.data) {
              context.commit(MutationTypes.SetToken, res.data);
              store.dispatch("user/getUserInfo", { token: res.data.authenticationToken });
              resolve();
            }
          })
          .catch((e) => {
            store.dispatch("auth/logout")
            console.error("LOGIN FAILED", e);
            reject();
          });
      }).finally(() => (_refreshingToken = null)));
    },
    register: (context, params) => {
      return new Promise<void>((resolve, reject) => {
        Authentication.apiAccountRegisterPost({
          email: params.email!,
          password: params.password!
        })
          .then((res) => {
            resolve();
          })
          .catch((e) => {
            reject();
          });
      });
    },
    forgotPassword: (context, params) => {
      return new Promise<void>((resolve, reject) => {
        Authentication.apiAccountForgotPasswordPost(params.emailAddress)
          .then((res) => {
            resolve();
          })
          .catch((e) => {
            reject();
          });
      });
    },
    resetPassword: (context, params) => {
      return new Promise<void>((resolve, reject) => {
        Authentication.apiAccountResetForgotPasswordPost(
          params.emailAddress,
          params.passwordResetToken,
          params.newPassword
        )
          .then((res) => {
            resolve();
          })
          .catch((e) => {
            reject();
          });
      });
    },
  },

  namespaced: true,
};
