import store from "@/store";
import axios, { AxiosStatic } from "axios";

// login call will use this
// import NodeRSA from 'node-rsa';
// const key = new NodeRSA(process.env.VUE_APP_PUBLIC_KEY!, 'pkcs1-public', {encryptionScheme:"pkcs1"});
// var encryptedPassword = key.encrypt(password, 'base64');

function isValid(date:any) {
  return date && new Date().getTime() < new Date(date).getTime()
}

export function ConfigureAxios() {
  let hasCompleted = false;

  axios.interceptors.request.use(
    (req) => {
      hasCompleted = false;

      setTimeout(() => {
        if(!hasCompleted) {
          store.dispatch("misc/setIsLoading", true);
        }
      }, 100);

      const url = new URL(req.url!);
      if (url.pathname.includes("/Account")) return req;
      let tkn = store.getters["auth/token"];
      let expiration = store.getters["user/expiry"];
      if(tkn !== null){
        if(!tkn.refreshToken) {
          store.dispatch("auth/logout") //clear login data (token, profile etc) without actually logging out
          tkn = null
          return req
        }
        if(!isValid(expiration)) {
          return store.dispatch("auth/refresh").then((res) => {
            tkn = store.getters["auth/token"];
            req.headers!.Authorization = `Bearer ${tkn.authenticationToken}`;
            return req;
          });
        }
        else if(isValid(expiration) && tkn.authenticationToken){
          req.headers!.Authorization = `Bearer ${tkn.authenticationToken}`;
        }
      }
      return req;
    },
    async (error) => {
      hasCompleted = true;
      store.dispatch("misc/setIsLoading", false);
      store.dispatch("misc/setIsLoadingOverride", false);
      console.log("ded");
      return Promise.reject(error);
    }
  );
  //TODO https://dev.to/franciscomendes10866/how-to-use-axios-interceptors-b7d



  axios.interceptors.response.use(
    (response) => {
      hasCompleted = true;
      store.dispatch("misc/setIsLoading", false);
      store.dispatch("misc/setIsLoadingOverride", false);
      // console.info(response);
      return response;
    },
    async (error) => {
      hasCompleted = true;
      store.dispatch("misc/setIsLoading", false);
      store.dispatch("misc/setIsLoadingOverride", false);
      let originalRequestRetry = false;

      let status: number = 0;
      if (error && Object.prototype.hasOwnProperty.call(error, "response")) {
        status = error.response?.status || 0;
      }
      
      if ([401].includes(status) && !originalRequestRetry) {
        return store.dispatch("auth/refresh").then(() => {
          console.log("Here " + status);
          return axios.request(error.config);
        });
      }
      return Promise.reject(error);
    }
  );
}
