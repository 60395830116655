import Vue from 'vue'

import 'normalize.css'
import ElementUI from 'element-ui'
import UniqueId from 'vue-unique-id';

// @ts-ignore
import locale from 'element-ui/lib/locale/lang/en'
import SvgIcon from 'vue-svgicon'

import '@/styles/element-variables.scss'
import '@/styles/index.scss'

import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import '@/icons/components'
import '@/permission'
import { UserModule } from "@/store/modules/user"
import { AuthModule } from "@/store/modules/auth"
import { MiscModule } from "@/store/modules/misc"
import { ConfigureAxios } from "@/network/auth-interceptor"
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
library.add(fas, faScrewdriverWrench)
ConfigureAxios()
store.registerModule("user", UserModule)
store.registerModule("auth", AuthModule)
store.registerModule("misc", MiscModule)

Vue.mixin({
  computed: {
    isLoading() {
      return store.getters["misc/isLoading"] || store.getters["misc/isLoadingOverride"];
    }
  }
})

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(ElementUI, { locale })
Vue.use(UniqueId)
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
})

Vue.config.productionTip = true

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
