/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'tools': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path d="M.848 26.91a2.998 2.998 0 0 0 2.122 5.12 2.99 2.99 0 0 0 2.122-.878l9.85-9.85-4.244-4.242-9.85 9.85zM25.466 8.654l4.286-2.218L32 2.094 29.906 0l-4.342 2.248-2.218 4.286-5.224 5.224 2.12 2.12zm.938 11.386c-.136-.008-.266-.04-.404-.04-.732 0-1.426.15-2.074.39L11.61 8.074C11.85 7.426 12 6.732 12 6c0-.138-.032-.268-.04-.404C11.748 2.474 9.176 0 6 0c-.924 0-1.79.226-2.572.6l3.986 3.986c.21.21.354.458.45.72.262.71.12 1.536-.45 2.108C7.024 7.804 6.512 8 6 8c-.236 0-.47-.052-.694-.136a1.984 1.984 0 0 1-.72-.45L.6 3.428A5.937 5.937 0 0 0 0 6c0 3.176 2.474 5.748 5.596 5.96.136.008.266.04.404.04.732 0 1.426-.15 2.074-.39L20.39 23.926A5.951 5.951 0 0 0 20 26c0 .138.032.268.04.404C20.252 29.526 22.824 32 26 32c.924 0 1.79-.226 2.572-.6l-3.986-3.986a1.957 1.957 0 0 1-.45-.72 1.996 1.996 0 0 1 .45-2.108c.39-.39.902-.586 1.414-.586.236 0 .47.052.694.136.262.098.51.24.72.45l3.986 3.986c.374-.782.6-1.648.6-2.572 0-3.176-2.474-5.748-5.596-5.96z"></path>'
  }
})
