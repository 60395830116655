import axios from 'axios';
import * as VASCOAPI from "@/api-client";
export const axiosInstance = axios.create();

const globalConf = new VASCOAPI.Configuration({
  basePath: process.env.VUE_APP_ROOT_API
});

const Authentication: VASCOAPI.AccountApi = new VASCOAPI.AccountApi(globalConf);
const Users: VASCOAPI.UsersApi = new VASCOAPI.UsersApi(globalConf);
const Roles: VASCOAPI.RolesApi = new VASCOAPI.RolesApi(globalConf);
const Customers: VASCOAPI.CustomersApi = new VASCOAPI.CustomersApi(globalConf);
const CustomerOtps: VASCOAPI.CustomerOtpsApi = new VASCOAPI.CustomerOtpsApi(globalConf);
const CustomerPaymentMethods: VASCOAPI.CustomerPaymentMethodsApi = new VASCOAPI.CustomerPaymentMethodsApi(globalConf);
const Products: VASCOAPI.ProductsApi = new VASCOAPI.ProductsApi(globalConf);
const Orders: VASCOAPI.OrdersApi = new VASCOAPI.OrdersApi(globalConf);
const OrderHistory: VASCOAPI.OrderHistoriesApi = new VASCOAPI.OrderHistoriesApi(globalConf);
const UserActivity: VASCOAPI.AuditLogsApi = new VASCOAPI.AuditLogsApi(globalConf);
const CancelationReasons: VASCOAPI.CancelationReasonsApi = new VASCOAPI.CancelationReasonsApi(globalConf);
const Files: VASCOAPI.FilesApi = new VASCOAPI.FilesApi(globalConf);
const Imports: VASCOAPI.ImportsApi = new VASCOAPI.ImportsApi(globalConf);
const Payments: VASCOAPI.PaymentsApi = new VASCOAPI.PaymentsApi(globalConf);
const BillingDays: VASCOAPI.BillingDaysApi = new VASCOAPI.BillingDaysApi(globalConf);
const OrderMessages: VASCOAPI.OrderMessagesApi = new VASCOAPI.OrderMessagesApi(globalConf);
const OrderPaymentRequests: VASCOAPI.OrderPaymentRequestsApi = new VASCOAPI.OrderPaymentRequestsApi(globalConf);
const TelkomProductOrderService: VASCOAPI.TelkomProductOrderServicesApi = new VASCOAPI.TelkomProductOrderServicesApi(globalConf);

export { Authentication, Users, Roles, Customers, CustomerOtps, CustomerPaymentMethods, Products, Orders, OrderHistory, UserActivity, CancelationReasons, Files, Imports, Payments, BillingDays, OrderMessages, OrderPaymentRequests, TelkomProductOrderService };