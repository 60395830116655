
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Logo',
  props: {
    type: {
        type: String,
        default: "colour"
    },
    width: {
        type: String,
    },
    height: {
        type: String,
    },
    collapse: {
        type: Boolean,
        default: false
    }
  }
})
export default class extends Vue {

}
